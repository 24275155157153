export const environment = {
  isProduction: false,
  enableAngularProdMode: true,
  name: 'staging',
  apiHost: 'https://api-staging.lefty.io',
  sentryDsn: 'https://70805baab1674877a1ee4ee3e12650fd@sentry.lefty.io//28',
  firebase: {
    apiKey: 'AIzaSyDMPv47eH1hCcaMd8nnzonNSs1wcGMsqP8',
    authDomain: 'leftyv2-1136.firebaseapp.com',
    databaseURL: 'https://leftyv2-1136.firebaseio.com',
    projectId: 'leftyv2-1136',
    storageBucket: 'leftyv2-1136.appspot.com',
    messagingSenderId: '631454761349',
    appId: '1:631454761349:web:96974fd72a9c04b61e44ec',
  },
};
